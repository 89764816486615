<template>
  <v-chart class="chart" style="height: 38px;width:38px;padding:4px"
           :option="option"
           ref="simpleChart"
           :autoresize="true"/>

</template>

<script>

export default {
  props: {
    dataValue: {
      type: [Number,String],
      default: 0
    },
    dataColor: {
      type: String,
      default: '#00C0F3'
    },
  },

  data() {
    return {
      option: {
        tooltip: {
          show: false,
        },
        legend: {
          show: false
        },
        padding: 6,
        series: [
          {
            type: 'pie',
            radius: ['75%', '100%'],
            color: [this.dataColor, '#E9ECEF'],
            avoidLabelOverlap: false,
            legendHoverLink: false,
            selectedMode: false,
            itemStyle: {
              borderRadius: 50,
              borderColor: 'transparent',
              borderWidth: 2
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            emphasis: {
              label: {
                show: false,
              },
              show: false,
              selectedMode: false,
              hoverLink: false,
              legendHoverLink: false,
            },

            hoverAnimation: false,
            data: [
              {value: this.dataValue, name: 'value'},
              {value: 100 - this.dataValue, name: 'hidden'},
            ]
          }
        ]
      }
    }
  },
  methods:{

    clear(){
      console.log('===================')
      // this.$refs.simpleChart.clear()
    }
  }

};
</script>
