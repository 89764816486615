<template>
  <div class="chart-container">
    <div class="chart__heading">
      <div class="chart__heading-title">{{ $t('chart.pie.title') }}</div>
      <div class="chart__heading-subtitle">{{ filterTitle }}</div>
      <b-dropdown
          variant="background"
          class="chart-options"
          toggle-class="text-decoration-none rounded-10 px-4 py-4 "
          menu-class="rounded-8"
          no-caret
          dropleft
      >
        <template v-slot:button-content>
          <vertical-dots-icon/>
        </template>

        <b-dropdown-item @click="()=>changeFilter(7,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_7_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(30,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_30_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(12,'months')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_12_months') }}
          </span>
        </b-dropdown-item>


      </b-dropdown>

    </div>
    <v-chart className="chart" :option="option" :autoresize="true"/>
  </div>
</template>

<script>

export default {

  props: {
    males: [Number, String],
    females: [Number, String],
    uncompleted: [Number, String],
  },

  computed: {
    filterTitle() {
      if (this.filter) {
        const {value, type} = this.filter;
        return this.$i18n.t('chart.last_' + value + '_' + type);
      }

      return this.$i18n.t('chart.last_12_months');
    },
    option() {
      return {
        tooltip: {
          show: true,
          formatter: "{b} : {c} ({d}%)"
        },
        legend: {
          orient: 'horizontal',
          bottom: '20%',
          selectedMode: false,
          icon: 'circle',
          align: 'right'
        },
        textStyle: {
          fontFamily: 'din-next'
        },
        color: [
          '#1CD0B7',
          '#FA4798',
          '#b0bbbe',
        ],
        series: [
          {
            type: 'pie',
            radius: ['45%', '60%'],
            avoidLabelOverlap: true,
            clockwise: false,
            startAngle: 90,
            bottom: '20%',
            emphasis: {
              scaleSize: 4,
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0,0)'
              }
            },
            labelLine: {
              show: true
            },
            label: {

              formatter: " {c}  \n {b} ",

            },
            data: [
              {value: this.males, name: this.$t('chart.males')},
              {value: this.females, name: this.$t('chart.females')},
              {value: this.uncompleted, name: this.$t('chart.uncompleted')},
            ]
          }
        ],
        media: [ // each rule of media query is defined here
          {
            query: {
              maxAspectRatio: 1
            },   // write rule here

          },
        ]
      };
    }
  },

  data() {
    return {
      filter: ''
    }
  },
  methods: {
    changeFilter(value, type) {
      this.filter = {value, type};
      this.$emit('changeFilter', this.filter);
    }
  }
};
</script>

<style scoped>

</style>
